import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertDialogComponent } from './components/alerts/alert-dialog/alert-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DescriptionDialogComponent } from './components/dialogs/description-dialog/description-dialog.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { FullMonthPipe } from './pipes/full-month.pipe';
import { GetHourPipe } from './pipes/get-hour.pipe';
import { GetMinutePipe } from './pipes/get-minute.pipe';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MenuItemComponent } from './components/nav/sidenav/components/menu-item/menu-item.component';
import { DropDownMenuComponent } from './components/nav/sidenav/components/drop-down-menu/drop-down-menu.component';
import { NavigationComponent } from './components/nav/navigation/navigation.component';
import { FooterComponent } from './components/nav/footer/footer.component';
import { HeaderComponent } from './components/nav/header/header.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { CompletedDialogComponent } from './components/dialogs/completed-dialog/completed-dialog.component';
import { VideoDialogComponent } from './components/dialogs/video-dialog/video-dialog.component';
import { EmptyStateComponent } from './components/display/empty-state/empty-state.component';
import { ShareDialogComponent } from './components/dialogs/share-dialog/share-dialog.component';
import { BlockAlertComponent } from './components/alerts/block-alert/block-alert.component';
import { PreBlockAlertComponent } from './components/alerts/pre-block-alert/pre-block-alert.component';
import { PaymentsAlertComponent } from './components/alerts/payments-alert/payments-alert.component';
import { CustomStepperComponent } from './components/display/custom-stepper/custom-stepper.component';
import { DataDisplayCardComponent } from './components/cards/data-display-card/data-display-card.component';
import { SidenavComponent } from './components/nav/sidenav/sidenav.component';
import { FileDescriptionDialogComponent } from './components/dialogs/file-description-dialog/file-description-dialog.component';
import { StatusChipComponent } from './components/display/status-chip/status-chip.component';
import { GenericDataCardComponent } from './components/cards/generic-data-card/generic-data-card.component';
import { NotificationsComponent } from './components/nav/notifications/notifications.component';
import { NotificationCardComponent } from './components/nav/notifications/components/notification-card/notification-card.component';
import { CdkMenuModule } from '@angular/cdk/menu';


@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent,
    HeaderComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    CompletedDialogComponent,
    VideoDialogComponent,
    DescriptionDialogComponent,
    SafeUrlPipe,
    FullMonthPipe,
    EmptyStateComponent,
    GetHourPipe,
    GetMinutePipe,
    ShareDialogComponent,
    BlockAlertComponent,
    PreBlockAlertComponent,
    PaymentsAlertComponent,
    CustomStepperComponent,
    DataDisplayCardComponent,
    SidenavComponent,
    FileDescriptionDialogComponent,
    StatusChipComponent,
    MenuItemComponent,
    DropDownMenuComponent,
    GenericDataCardComponent,
    NotificationsComponent,
    NotificationCardComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ClipboardModule,

    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    TourMatMenuModule.forRoot(),
    CdkMenuModule,
  ],
  exports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NavigationComponent,
    HeaderComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ClipboardModule,
    CompletedDialogComponent,
    VideoDialogComponent,
    DescriptionDialogComponent,
    SafeUrlPipe,
    EmptyStateComponent,
    ShareButtonsModule,
    ShareIconsModule,
    CustomStepperComponent,
    DataDisplayCardComponent,
    FileDescriptionDialogComponent,
    StatusChipComponent,
    SidenavComponent,
    MenuItemComponent,
    DropDownMenuComponent,
    GenericDataCardComponent,
    CdkMenuModule,
    NotificationCardComponent,
  ],
  providers: [],
})
export class SharedModule {}
