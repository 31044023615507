import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';
import { UserDoc } from './../../../../shared/interfaces/interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileDescriptionDialogComponent } from 'src/app/shared/components/dialogs/file-description-dialog/file-description-dialog.component';
import { MultiDocsModalComponent } from '../multi-docs-modal/multi-docs-modal.component';
import { UploadFileModalComponent } from 'src/app/shared/components/upload-file-modal/upload-file-modal.component';
import moment from 'moment';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit {
  @Input() userDoc: UserDoc;
  @Input() changeDocState: (docId, state) => void;

  isUploading = false;

  constructor(
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void{
    console.log(this.userDoc)
  }

  onFileSelected(event, isMulti) {
    this.isUploading = true;
    if(isMulti){
      this.checkMultiFiles(event.target.files)
    }else{
      const file: File = event.target.files[0];
      this.checkFile(file);
    }
    console.log(event.target.files)
  }

  viewMultiDocs(){
    if(this.userDoc.documentos_adjuntos && this.userDoc.documentos_adjuntos.length > 0){
      console.log(this.userDoc.documentos_adjuntos)
     let dialogRef = this.dialog.open(MultiDocsModalComponent, {
      width: '25rem',
      maxHeight: '40rem',
      panelClass: ['multi-docs-modal'],
      data: {
        userDoc: this.userDoc,
        checkFile: this.checkFile,
        changeDocState: this.changeDocState
      }
    })
    dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  checkMultiFiles(filesObj){
    if(filesObj.length > 10){
      this.dataService.openAlert(
        '¡Son demasiados archivos!',
        'Sólo puedes subir hasta 10 archivos a la vez',
        'Aceptar',
        null, 
        'error',
        'error'
      );
    }else{
      const filesArr = Object.values(filesObj);
      const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];
      const validateFileSize = filesArr.find((file: any) => file.size > 10000000);
      if(!validateFileSize){
        this.postMultiDocs(filesObj) 
      }else{
        this.isUploading = false;
        this.dataService.openAlert(
          '¡Tu archivo es muy pesado!',
          'El archivo no debe exceder los 10MB, reduce su peso e intenta nuevamente',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    }
  }

  checkFile(file, isMulti = false, expireDate = null) {
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.name.split('.').pop();
    const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];

    if (file && fileSize <= 10) {
      if (acceptedFiles.includes(fileType)) {
        if(isMulti){
          this.updateDoc(file, isMulti)
        }
        this.userDoc.estado?.toLowerCase() === 'pendiente'
          ? this.postDoc(file, expireDate)
          : this.updateDoc(file, false, expireDate);
      } else {
        this.isUploading = false;
        this.dataService.openAlert(
          'No aceptamos este tipo de archivo',
          'Sólo puedes subir tus documentos como PDF o en formato de imagen: JPG, JPEG o PNG',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    } else {
      this.isUploading = false;
      this.dataService.openAlert(
        '¡Tu archivo es muy pesado!',
        'El archivo no debe exceder los 10MB, reduce su peso e intenta nuevamente',
        'Aceptar',
        null,
        'error',
        'error'
      );
    }
  }

  openUploadFileModal(){
    this.dialog.open(UploadFileModalComponent, {
      minWidth: '450px',
      maxWidth: '90vw',
      data: {
        title: this.userDoc.nombre,
        description: this.userDoc.motivo,
        isMulti: this.userDoc.carga_multiple,
        status: this.userDoc.estado,
        update:
          this.userDoc.estado.toLowerCase() !== 'pendiente' &&
          this.userDoc.estado.toLowerCase() !== 'aprobado' &&
          this.userDoc.id !== 0,
      },
    }).afterClosed().subscribe((data) => {
      if (data) {
        this.isUploading = true;
        const formattedDate = moment(data.pickDate).format('MM/DD/YYYY');
        this.checkFile(data.file[0], false, formattedDate);
      }else{
        console.log('no file')
      }
    })
  }

  postMultiDocs(files){
    this.dataService.postMultiDocs(files, this.userDoc.category_id).subscribe(
      (res) => {
        this.isUploading = false;
        this.dataService.openAlert(
          '¡Documentos cargados con éxito!',
          'Ya recibimos tus documentos, una vez que sean revisados verás el estado de la entrega aquí.',
          'Aceptar',
          null,
          'success',
          'check_circle'
        );
        this.changeDocState(this.userDoc.category_id, 'En revisión');
        // this.getDocs();
      },
      (error) => {
        this.isUploading = false;
        this.dataService.openAlert(
          'No pudimos cargar el documento',
          'Intenta de nuevo más tarde',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    );
  }

  postDoc(file, expireDate = null) {
    this.dataService.postDocument(file, this.userDoc.category_id, expireDate).subscribe(
      (res) => {
        this.isUploading = false;
        this.dataService.openAlert(
          '¡Documento cargado con éxito!',
          'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí.',
          'Aceptar',
          null,
          'success',
          'check_circle'
        );
        this.changeDocState(this.userDoc.category_id, 'En revisión');
        // this.getDocs();
      },
      (error) => {
        this.isUploading = false;
        this.dataService.openAlert(
          'No pudimos cargar el documento',
          'Intenta de nuevo más tarde',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    );
  }

  updateDoc(file, isMulti = false, expireDate = null) {
    if(isMulti){
      this.dataService.updateMultiDocs(file, this.userDoc.id).subscribe(
        (res) => {
          this.isUploading = false;
          this.dataService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            null,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          this.changeDocState(this.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.isUploading = false;
          this.openSnackBar(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      )
    }else{
      this.dataService.updateDocument(file, this.userDoc.id, expireDate).subscribe(
        (res) => {
          this.isUploading = false;
          this.dataService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            null,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          this.changeDocState(this.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.isUploading = false;
          this.openSnackBar(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      );
    }
  }
  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [`${type}-snackbar`],
    });
  }

  openMotivo(): void {
    const dialogRef = this.dialog.open(FileDescriptionDialogComponent, {
      minWidth: '450px',
      maxWidth: '90vw',
      data: {
        title: this.userDoc.nombre,
        description: this.userDoc.motivo,
        status: this.userDoc.estado,
        update:
          this.userDoc.estado.toLowerCase() !== 'pendiente' &&
          this.userDoc.estado.toLowerCase() !== 'aprobado' &&
          this.userDoc.id !== 0,
      },
    });

    dialogRef.afterClosed().subscribe((file) => {
      if (file) {
        this.isUploading = true;
        this.checkFile(file);
      }
    });
  }
}
