import { ContestLayoutComponent } from './shared/layouts/contest-layout.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { CountdownModule } from 'ngx-countdown';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeLayoutComponent } from './shared/layouts/home-layaot.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CardComponent } from './pages/home/components/card/card.component';
import { CardcanvasComponent } from './pages/home/components/cardcanvas/cardcanvas.component';
import { FormComponent } from './pages/perfil/components/form/form.component';
import {
  CardprofileComponent,
  ModalComponent,
} from './pages/perfil/components/cardprofile/cardprofile.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Redux
import { StoreModule } from '@ngrx/store';
import { store } from './redux/store/store';

import { ConcursoComponent } from './pages/ceaden/aprende-emprende/concurso.component';
import { CronogramaConcursoComponent } from './pages/ceaden/aprende-emprende/cronograma-concurso/cronograma-concurso.component';
import { FormConcursoComponent } from './pages/ceaden/aprende-emprende/form-concurso/form-concurso.component';
import { HeadConcursoComponent } from './pages/ceaden/aprende-emprende/head-concurso/head-concurso.component';
import { SobreConcursoComponent } from './pages/ceaden/aprende-emprende/sobre-concurso/sobre-concurso.component';
import { CardRequisitoComponent } from './pages/ceaden/aprende-emprende/sobre-concurso/card-requisito/card-requisito.component';
import { CardCriterioComponent } from './pages/ceaden/aprende-emprende/sobre-concurso/card-criterio/card-criterio.component';
import { CardMiembroComponent } from './pages/ceaden/aprende-emprende/form-concurso/card-miembro/card-miembro.component';
import { CardTutorComponent } from './pages/ceaden/aprende-emprende/form-concurso/card-tutor/card-tutor.component';
import { PremiosConcursoComponent } from './pages/ceaden/aprende-emprende/premios-concurso/premios-concurso.component';
import { CardCronogramaComponent } from './pages/ceaden/aprende-emprende/cronograma-concurso/card-cronograma/card-cronograma.component';
import { ContactoConcursoComponent } from './pages/ceaden/aprende-emprende/contacto-concurso/contacto-concurso.component';
import { PeticionesComponent } from './pages/peticiones/peticiones.component';
import { NuevoTicketComponent } from './pages/peticiones/components/nuevo-ticket/nuevo-ticket.component';
import { DialogSuccess } from './pages/peticiones/components/nuevo-ticket/nuevo-ticket.component';
import { EstadoTicketComponent } from './pages/peticiones/components/estado-ticket/estado-ticket.component';
import { UploadConcursoComponent } from './pages/ceaden/aprende-emprende/form-concurso/upload-concurso/upload-concurso.component';
import { DndDirective } from './pages/ceaden/aprende-emprende/form-concurso/upload-concurso/dnd.directive';

import { DndInscripcionDirective } from './pages/adenu-internacional/upload-internacional/dnd-inscripcion.directive';
import { UploadInternacionalComponent } from './pages/adenu-internacional/upload-internacional/upload-internacional.component';
import { ContactoInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/contacto-internacional/contacto-internacional.component';
import { CardCronogramaInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/cronograma-internacional/card-cronograma-internacional/card-cronograma-internacional.component';
import { PasosInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/pasos-internacional/pasos-internacional.component';
import { CardPasoInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/sobre-internacional/card-paso-internacional/card-paso-internacional.component';
import { SobreInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/sobre-internacional/sobre-internacional.component';
import { EstadoFinancieroComponent } from './pages/estado-financiero/estado-financiero.component';
import { PaymentCardComponent } from './pages/estado-financiero/components/payment-card/payment-card.component';
import { PaymentTableComponent } from './pages/estado-financiero/components/payment-table/payment-table.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { CardEventoComponent } from './pages/eventos/components/card-evento/card-evento.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { GanadoresConcursoComponent } from './pages/ceaden/aprende-emprende/ganadores-concurso/ganadores-concurso.component';
import { MovilidadDocentesComponent } from './pages/adenu-internacional/movilidad-docentes/movilidad-docentes.component';
import { FormularioDocentesComponent } from './pages/adenu-internacional/movilidad-docentes/formulario-docentes/formulario-docentes.component';
// eslint-disable-next-line max-len
import { HeadInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/head-internacional/head-internacional.component';
import { CronogramaInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/cronograma-internacional/cronograma-internacional.component';
import { AdenuInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/adenu-internacional.component';
import { CardRequisitoInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/sobre-internacional/card-requisito-internacional/card-requisito-internacional.component';
import { FichaInscripcionComponent } from './pages/adenu-internacional/ficha-inscripcion/ficha-inscripcion.component';
import {
  ConveniosComponent,
  DetailDialogComponent,
} from './pages/adenu-internacional/movilidad-salientes/convenios/convenios.component';
import { ContactDialogComponent } from './pages/adenu-internacional/contact-dialog/contact-dialog.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DocumentCardComponent } from './pages/perfil/components/document-card/document-card.component';
import { NgxMatTelInputModule } from 'ngx-mat-tel-input';
import { CeadenComponent } from './pages/ceaden/ceaden.component';
import { ProgramCardComponent } from './pages/ceaden/components/program-card/program-card.component';
import { ProgramaImpulsoComponent } from './pages/ceaden/programa-impulso/programa-impulso.component';
import { FormImpulsoComponent } from './pages/ceaden/programa-impulso/components/form-impulso/form-impulso.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RegisterComponent } from './pages/register/register.component';
import { MoreInfoDialogComponent } from './pages/estado-financiero/components/more-info-dialog/more-info-dialog.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { BannerComponent } from './pages/home/components/banner/banner.component';
import { EventosBannerComponent } from './pages/home/components/eventos-banner/eventos-banner.component';
import { FormacionAcademicaComponent } from './pages/perfil/components/formacion-academica/formacion-academica.component';
import { CreateDialogComponent } from './pages/perfil/components/formacion-academica/create-dialog/create-dialog.component';
import { NotificacionesComponent } from './pages/notificaciones/notificaciones.component';
import { NotasComponent } from './pages/notas/notas.component';
import { DataDisplayCardNotesComponent } from './shared/components/data-display-card/data-display-card.component';
import { NotasTableComponent } from './shared/components/notas-table/notas-table.component';
import { PlanEstudioComponent } from './pages/plan-estudio/plan-estudio.component';
import { CardcareerComponent } from './pages/plan-estudio/components/cardcareer/cardcareer.component';
import { PlanTableComponent } from './pages/plan-estudio/components/plan-table/plan-table.component';
import { MultiDocsModalComponent } from './pages/perfil/components/multi-docs-modal/multi-docs-modal.component';
import { CourseComponent } from './pages/course/course.component';
import { ResourceCardComponent } from './pages/course/resource-card/resource-card.component';
import { CourseViewerComponent } from './pages/course/course-viewer/course-viewer.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { UploadFileModalComponent } from './shared/components/upload-file-modal/upload-file-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    ContestLayoutComponent,
    PerfilComponent,
    NotasTableComponent,
    NotFoundComponent,
    CardComponent,
    DataDisplayCardNotesComponent,
    CardcanvasComponent,
    ResourceCardComponent,
    FormComponent,
    CardprofileComponent,
    ModalComponent,
    BannerComponent,
    EventosBannerComponent,
    ConcursoComponent,
    CronogramaConcursoComponent,
    FormConcursoComponent,
    HeadConcursoComponent,
    SobreConcursoComponent,
    CardCriterioComponent,
    CardRequisitoComponent,
    CardMiembroComponent,
    CourseComponent,
    CardTutorComponent,
    PremiosConcursoComponent,
    CardCronogramaComponent,
    ContactoConcursoComponent,
    PeticionesComponent,
    NuevoTicketComponent,
    DialogSuccess,
    EstadoTicketComponent,
    UploadConcursoComponent,
    DndDirective,
    DndInscripcionDirective,
    UploadInternacionalComponent,
    ContactoInternacionalComponent,
    CardCronogramaInternacionalComponent,
    PasosInternacionalComponent,
    SobreInternacionalComponent,
    HeadInternacionalComponent,
    CronogramaInternacionalComponent,
    AdenuInternacionalComponent,
    CardPasoInternacionalComponent,
    CardRequisitoInternacionalComponent,
    FichaInscripcionComponent,
    ConveniosComponent,
    DetailDialogComponent,
    EstadoFinancieroComponent,
    PaymentCardComponent,
    PaymentTableComponent,
    EventosComponent,
    CardEventoComponent,
    GanadoresConcursoComponent,

    MovilidadDocentesComponent,
    FormularioDocentesComponent,
    ContactDialogComponent,
    DocumentCardComponent,
    CeadenComponent,
    ProgramCardComponent,
    ProgramaImpulsoComponent,
    FormImpulsoComponent,
    RegisterComponent,
    MoreInfoDialogComponent,
    FormacionAcademicaComponent,
    CreateDialogComponent,
    NotificacionesComponent,
    NotasComponent,
    UploadFileModalComponent,
    CourseViewerComponent,
    PlanEstudioComponent,
    CardcareerComponent,
    PlanTableComponent,
    MultiDocsModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatFormFieldModule,
    SharedModule,
    Ng2SearchPipeModule,
    StoreModule.forRoot(store),
    StoreDevtoolsModule.instrument({
      maxAge: 4,
    }),
    ClipboardModule,
    NgxMatTelInputModule,
    CountdownModule,
    TourMatMenuModule.forRoot(),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    AuthGuard,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    Title,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
