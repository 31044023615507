<div class="main">
  <h3>Sube tu documento</h3>
  <div class="form-group">
    <label for="">Elege una fecha de vencimiento para tu documento</label>
      <mat-form-field appearance="fill">
        <mat-label>Elige una fecha de vencimiento</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="dataSelected.pickDate">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    
    <div class="form-group upload-document-group">
      <label for="fileInput">Elige un documento</label>
      <input [multiple]="data.isMulti ? 'multiple' : 'none'" #fileInput type="file" hidden (change)="onFileSelected($event)">
      <button mat-raised-button color="primary" (click)="fileInput.click()">Cargar documento</button>
      <span class="file-name" *ngIf="dataSelected.file">{{dataSelected.file.name }}</span>
    </div>
    
    
    <div class="button-section">
      <button mat-raised-button color="primary" (click)="uploadFile()">Subir</button>
      <button mat-raised-button class="cancel-btn" (click)="closeModal()">Cancelar</button>
    </div>
</div>
  