import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss']
})
export class UploadFileModalComponent implements OnInit {
  dataSelected = {
    pickDate: null,
    file: null
  };

  constructor(
    public dialogRef: MatDialogRef<UploadFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  closeModal() {
    this.dialogRef.close();
  }

  uploadFile() {
    console.log(this.dataSelected)
    if (this.dataSelected.pickDate && this.dataSelected.file) {
      this.dialogRef.close(this.dataSelected);
    } else {
      console.log('Please select a date and a file');
    }
  }
  onFileSelected(event) {
    this.dataSelected.file = event.target.files;
    console.log('file selected', this.dataSelected.file);
  }
}
